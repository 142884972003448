import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { RiskMethodologyImpactFactorDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class RiskMethodologyImpactFactorDataService extends BaseRequestControllerService<RiskMethodologyImpactFactorDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.risk, '/risk-methodology-impact-factors', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'options',
      'codePrefix',
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`risk/risk-item/list`);
  }
}