<div class="grid flex-1 p-fluid">
  <div [class]="'col-4'">
    <span class="p-float-label">
      <input
        appMaxCharacterValidator
        class="w-full"
        pInputText
        [(ngModel)]="threshold.name"
        type="text"
        [placeholder]="moduleFieldString + '.name.label' | translate: 'Name'"
        [readOnly]="viewMode == 'view'"
      />

      <!-- <label>{{"Name"}}</label> -->
    </span>
  </div>

  <div [class]="'col-4'" class="">
    <span>
      <span class="flex justify-content-between w-100 mt-1">
        <small>{{ options?.floor }}</small>
        <p-inplace
          [preventClick]="disabled || viewMode == 'view'"
          [disabled]="disabled || viewMode == 'view'"
          [closable]="true"
          styleClass="inplace-algin-super"
          [style]="{ verticalAlign: 'super' }"
        >
          <ng-template pTemplate="display">
            <small>{{ threshold?.value }}</small>
          </ng-template>
          <ng-template pTemplate="content">
            <input appMaxCharacterValidator type="number" [(ngModel)]="threshold.value" pInputText />
          </ng-template>
        </p-inplace>
        <small>{{ options?.ceil }}</small>
      </span>

      <p-slider
        [min]="options?.floor"
        [max]="options?.ceil"
        [step]="1"
        [disabled]="disabled || viewMode == 'view'"
        [(ngModel)]="threshold.value"
      ></p-slider>
    </span>
  </div>

  <div [class]="'col-3'" class="flex justify-content-center">
    <label class="mx-2">{{ moduleFieldString + '.color.label' | translate: 'Color' }}</label>
    <ngx-colors
      ngx-colors-trigger
      class="custom-colorpicker"
      [colorsAnimation]="null"
      [(ngModel)]="threshold.color"
      [disabled]="viewMode == 'view'"
    ></ngx-colors>
  </div>
  @if (viewMode != 'view') {
    <div [class]="'col-1'">
      <div class="flex align-items-start justify-content-center gap-2">
        <app-button [action]="tableActions[0]"></app-button>
      </div>
    </div>
  }
</div>
