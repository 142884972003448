import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, IAction, IDynamicComponent } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-impact-factor-options-form-popup',
  templateUrl: './impact-factor-options-form-popup.component.html',
  styleUrls: ['./impact-factor-options-form-popup.component.scss'],
})
export class ImpactFactorOptionsFormPopupComponent extends BaseForm<any> implements OnInit, AfterViewInit {
  submitButtonAction: IAction = {
    id: 1,
    label: 'Ok',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
    status$: this.formValid$,
  };
  impactValueOptions: { name: string; value: any }[] = [];
  optionsFields: IDynamicComponent[] = [];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public viewModeService: ViewModeService
  ) {
    super(viewModeService, 'RISK_METHODOLOGY');
    this.impactValueOptions = this.config?.data?.impactValueOptions;
  }

  ngOnInit(): void {
    this.optionsFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Impact Value',
          name: 'value',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'name',
              optionValue: undefined,
              items: this.impactValueOptions,
              showStatusBadge: false,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    ];
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setData(this.config.data);
    }, 100);
    this._setupSubscriptions();
  }
  onSubmitForm() {
    this.ref.close(this.getData());
  }
  getData() {
    return {
      description: this.formGroup.value.description,
      options: this.arrayToMap(this.formGroup.value.options),
    };
  }
  mapToArray(obj: { [key: string]: any }): { name: string; value: any }[] {
    if (typeof obj !== 'object' || obj === null) {
      return [];
    }
    if (Array.isArray(obj)) {
      return obj;
    }
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
      arr.push({ name: key, value: value });
    }
    return arr;
  }

  arrayToMap(arr: { name: string; value: any }[]): { [key: string]: any } {
    if (!Array.isArray(arr)) {
      return {};
    }
    const obj = {};
    for (const { name, value } of arr) {
      obj[name] = value;
    }
    return obj;
  }
  setData(data: any) {
    this.formGroup.patchValue({ description: data.description, options: this.mapToArray(data.options) });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      description: new FormControl(null),
      options: new FormControl([]),
      // questionType: new FormControl(null, Validators.required),
      // options: new FormControl(null),
    });
  }
}
