<div class="border border-gray-200 border border border-gray-200">
  <!-- <h4 *ngIf="!showSummaryOnly" class="header-title mb-3">
  Risk Matrix
  <i
    class="pi pi-external-link ml-2 cursor-pointer"
    style="font-size: 1rem"
    (click)="openModal()"
    *ngIf="showExpandButton"
  ></i>
</h4>
<p *ngIf="!showSummaryOnly" class="text-muted font-14 pb-2">

  Risk rating = Impact x Probability

</p> -->

  <div class="w-full">
    <p-table
      [value]="_methodology?.likelihoodValuesDto"
      styleClass="p-datatable-gridlines"
      [tableStyle]="{ 'min-width': '45rem', 'text-align': 'center' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          @for (impact of _methodology?.impactValuesDto; track impact; let i2 = $index) {
            <th>{{ impact.name }} ({{ impact.value }})</th>
          }
        </tr>
      </ng-template>

      <ng-template pTemplate="body" styleClass="p-datatable-tbody border-noround" let-likelihood>
        <tr>
          @for (impact of _methodology.impactValuesDto; track impact; let j2 = $index) {
            @if (j2 == 0) {
              <td class="py-2 pl-1 border-noround" scope="row">
                <small>{{ likelihood.name }} ({{ likelihood.value }})</small>
              </td>
            }
            <td
              class="py-2 pl-1 border-noround text-0 text-center align-items-center justify-content-center"
              [ngStyle]="{ 'background-color': getCellColor(impact.value * likelihood.value) }"
            >
              <!-- <p-chip [label]="(impact.value * likelihood.value)?.toFixed(0)"></p-chip> -->
              <app-badge-item
                [displayText]="true"
                [status]="'white'"
                [badgeType]="'solid'"
                [text]="(impact.value * likelihood.value)?.toFixed(0)"
                [styleClass]="'m-auto'"
              ></app-badge-item>
              <!-- <small style="color: rgb(255, 255, 255);mix-blend-mode: difference;">
            {{impact.value * likelihood.value}}</small> -->
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
