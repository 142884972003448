import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { RiskMethodologyLikelihoodValueDto } from '@shared/classes';
import { BaseRequestControllerService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root'
})
export class RiskMethodologyLikelihoodValueDataService extends BaseRequestControllerService<RiskMethodologyLikelihoodValueDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.risk, '/risk-methodology-likelihood-values', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'name',
      'value',
      'occuresFrom',
      'occuresTo'
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`risk/risk-item/list`);
  }
}