import { Component, Input, OnInit } from '@angular/core';
import { RiskMethodology } from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { find } from 'lodash-es';
import { SummaryPopupComponent } from '../summary-popup/summary-popup.component';

@Component({
  selector: 'app-risk-methodology-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
})
export class SummaryComponent implements OnInit {
  _methodology: RiskMethodology = null;

  @Input() set methodology(value: RiskMethodology) {
    this._methodology = value;
  }

  @Input() maxThreshold: number;

  @Input() showExpandButton: boolean = true;
  @Input() showSummaryOnly: boolean = false;
  constructor(private _dialogService: AppDialogService) { }

  ngOnInit(): void { }

  getCellColor(cellValue) {
    let threshold = find(this._methodology?.thresholdValuesDto, function (o) {
      return o.value >= cellValue;
    });

    if (threshold != undefined) return threshold.color;
    else return 'transparent';
  }

  openModal() {
    this._dialogService.showDialog(SummaryPopupComponent, 'Risk Matrix', (e) => { }, {
      data: {
        maxThreshold: this.maxThreshold,
        methodology: this._methodology,
        showExpandButton: false,
      },
      width: '60%',
      height: '60%',
    });
  }
}
